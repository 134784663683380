/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


//ion content

ion-content,
.ifeet-boxed {
    max-width: 1200px;
    left: 50%;
    transform: translateX(-50%);
}

// Items
ion-item {
    --background: #fff !important;
    --color: var(--ion-color-dark);
    cursor: pointer;
}

// Pagination

.ngx-pagination {
    transform: translateX(-20px);

    .disabled {
        opacity: 30%;
    }
}

// input 
.ifeet-fileinput .native-wrapper {
    height: 30px;
    margin-top: -7px;
}

ion-input {
    .input-wrapper {
        gap: 10px
    }
}

ion-select {
    .input-wrapper {
        display: block !important;
    }
}

ion-input,
ion-select {
    --background: var(--ion-color-light) !important;
    --border-radius: 5px;
    --padding-bottom: 18px !important;
    --padding-end: 10px !important;
    --padding-start: 20px !important;
    --highlight-height: 3px !important;
    --padding-top: 8px !important;
    --placeholder-opacity: 40% !important;
}

ion-select {
    --padding-top: 30px !important;
    --padding-bottom: 30px !important;
}

ion-checkbox.ng-invalid.ng-dirty {
    --border-color: var(--ion-color-danger);
    color: var(--ion-color-danger);
}


// button
ion-button.ifeet-button {
    --border-radius: 5px !important;
    --padding-top: 25px !important;
    --padding-bottom: 25px !important;
    --padding-start: 30px !important;
    --padding-end: 30px !important;
    --box-shadow: none;
    --border-radius: 5px;
}

.terms-url {
    margin-left: 37px;
    display: block;
    position: relative;
    z-index: 1;
    margin-bottom: 2px;
    cursor: pointer;
}


ion-button {
    --box-shadow: none;
}

// ion item no margin
.item-remove-spacings {
    --inner-padding-bottom: 0;
    --inner-padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
}



// textarea 
ion-textarea {
    --background: var(--ion-color-light) !important;
    --border-radius: 5px !important;
    --padding-bottom: 5px;
    --padding-top: 2px !important;
    --padding-start: 17px !important;
    --padding-end: 17px !important;
}

// fab button 

.ios .ifeet-tabbar-fab {
    transform: translateY(-17px) !important;
}

//sticky elements

.ifeet-sticky {
    position: sticky !important;
    top: -17px !important;
    z-index: 9 !important;
    display: block !important;
    background-color: #fff;
    border-radius: 5px !important;

}

//choose file button
input::file-selector-button {
    display: none;
}

ion-input.ifeet-fileinput {
    --padding-bottom: 20px !important;
    --padding-top: 40px !important;
    height: 75px !important;
}

.ifeet-file-input-label {
    display: block;
    position: relative;
    z-index: 9;
}


// divider 

hr {
    border-top: 1px solid var(--ion-color-medium);
    opacity: 20%;
    width: 100%;
}

//ion select error message
.ifeet-input-error {
    color: var(--ion-color-danger);
    font-size: 12px;
    display: block;
    padding-top: 5px;
    padding-inline-start: 20px;

    &.ios {
        padding-top: 0px;
    }
}

.ios .ifeet-input-error {
    padding-top: 0px;
}

// input error messages
ion-input .error-text,
ion-input .helper-text {
    margin-bottom: 9px;
}


// center elements 

.ifeet-center-items-y {
    display: flex;
    align-items: center;
}

// icons
.ifeet-icon-text {
    display: flex;
    align-items: center;
    gap: 5px
}

.ifeet-buttons-end {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


}

ion-icon {
    pointer-events: none;
}


// image
.ifeet-image {
    max-width: 300px;
    width: 45%;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    animation: cards 0.5s ease 0s 1 normal none;
}


// segments
ion-segment {
    height: 45px;
}

// center 
.ifeet-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
}

//card

ion-card.ifeet-card {
    box-shadow: none;
    border-radius: 5px;
    box-shadow: 0 0 40px -20px var(--ion-color-medium);

}

.insole-card-size {
    width: 225px;
}

ion-card.ifeet-card:hover {
    --background: var(--ion-color-light);

}

// img 
img {
    border-radius: 5px;
}

.ifeet-image-transparent-bg {
    mix-blend-mode: multiply;
}

// date picker
ion-datetime {
    max-width: 380px !important;
    width: 380px !important;
}

.ifeet-datetime-input {
    background: var(--ion-color-light) !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 37px;
    padding-bottom: 13px;
    border-radius: 7px;
    width: 100%;
    justify-content: start;

}

.ifeet-datetime-input-label,
.ifeet-file-input-label {
    font-size: 12px !important;
    margin-left: 15px;
    margin-bottom: -20px;
    transform: translateY(12px);
}

//ion refresher position ios

.ios {
    ion-refresher {
        position: relative !important;
        z-index: 99 !important;
        margin-top: -30px;
        margin-bottom: -30px;
        transform: translateY(-5px);
        scale: 0.8;
    }


}

// Responsive design
@media screen and (min-width: 600px) {
    .ifeet-image {
        max-width: 300px;
        width: 30%;
    }
}