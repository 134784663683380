// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {
    --ion-color-primary: #33B5B7;
    --ion-color-primary-rgb: 51, 181, 183;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #2d9fa1;
    --ion-color-primary-tint: #47bcbe;

    --ion-color-secondary: #33B5B7;
    --ion-color-secondary-rgb: 51, 181, 183;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #2d9fa1;
    --ion-color-secondary-tint: #47bcbe;

    --ion-color-tertiary: #33B5B7;
    --ion-color-tertiary-rgb: 51, 181, 183;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #2d9fa1;
    --ion-color-tertiary-tint: #47bcbe;

    --ion-color-success: #2dd55b;
    --ion-color-success-rgb: 45, 213, 91;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28bb50;
    --ion-color-success-tint: #42d96b;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #c5000f;
    --ion-color-danger-rgb: 197, 0, 15;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ad000d;
    --ion-color-danger-tint: #cb1a27;

    --ion-color-light: #eff7f7;
    --ion-color-light-rgb: 246, 248, 252;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d8dade;
    --ion-color-light-tint: #f7f9fc;

    --ion-color-medium: #787878;
    --ion-color-medium-rgb: 120, 120, 120;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #6a6a6a;
    --ion-color-medium-tint: #868686;

    --ion-color-dark: #2f2f2f;
    --ion-color-dark-rgb: 47, 47, 47;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #292929;
    --ion-color-dark-tint: #444444;

}